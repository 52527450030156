import axios from "axios";
import { Toast } from "../../util/Toast";

import { GET_MONTHLY_STATUS, GET_SELLER, GET_TODAY_STATUS, SET_ADMIN, UNSET_ADMIN, UPDATE_PROFILE } from "./types";
import { key } from "../../util/Config";

export const login = (data) => (dispatch) => {
  axios
    .post(`agency/login?key=${key}`, data)
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        Toast("success", "You have successfully logged in Ⴆσσɱҽɾ Lιʋҽ.");
        setTimeout(() => {
          window.location.href = "/host/dashboard";
        }, 100);
        dispatch({ type: SET_ADMIN, payload: res.data.token });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
export const getTodayStatus = (hostId) => (dispatch) => {
  axios
    .get(`host/todayStats?hostId=${hostId}`)
    .then((res) => {
      dispatch({
        type: GET_TODAY_STATUS,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log("error", error));
};
export const getMonthlyStatus = (hostId,selectedDate) => (dispatch) => {
  axios
    // .get(`host/todayStats?hostId=${hostId}`)
    .get(`host/monthlyStats?hostId=${hostId}&month=${selectedDate}`)

    .then((res) => {
      dispatch({
        type: GET_MONTHLY_STATUS,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log("error", error));
};

export const getProfile = (hostId) => (dispatch) => {
  // Return the promise
  return axios
    .get(`host/profile?hostId=${hostId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_SELLER, payload: res.data?.data });
        return res.data?.data; // Return the profile data to the caller
      } else {
        Toast("error", res.data.message);
        return null; // Return null if there is an error
      }
    })
    .catch((error) => {
      console.log("error", error.message);
      return null; // Return null in case of an error
    });
};



export const changePassword = (data) => (dispatch) => {
  axios
    .put("admin", data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Password changed successfully.");
        setTimeout(() => {
          dispatch({ type: UNSET_ADMIN });
          window.location.href = "/host";
        }, [3000]);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const updateProfile = (agency_id, data) => (dispatch) => {
  axios
    .patch(`agency/profile/${agency_id}`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Profile updated successfully.");
        dispatch({ type: UPDATE_PROFILE, payload: res.data?.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
